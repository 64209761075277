export const cookies = {
  content: `
This page provides you with important information about the use of cookies on our website. By using our website, you consent to the use of cookies as described in this Cookie Policy.
## What are Cookies?
Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit a website. They are widely used to make websites work more efficiently and provide a better user experience. Cookies enable websites to remember your actions and preferences (such as login information, language selection, font size, and other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another.
## Types of Cookies We Use:
1. Essential Cookies:
These cookies are necessary for the proper functioning of our website. They enable you to navigate our site and use its features, such as accessing secure areas and making transactions. Without these cookies, certain services you've requested cannot be provided.
2. Analytical/Performance Cookies:
We use these cookies to collect information about how visitors use our website, such as which pages are visited most often and if any error messages occur. This helps us improve the performance and usability of our website and understand what content is most useful to our visitors.
3. Functionality Cookies:
Functionality cookies allow our website to remember choices you make (such as your username, language, or region) and provide enhanced, more personalized features. These cookies can also be used to remember changes you've made to text size, fonts, and other customizable parts of web pages. They may also be used to provide services you've requested, such as watching a video or commenting on a blog.
## Cookie Consent:
By continuing to browse the site you consent to the use of cookies as described in this Cookie Policy.
## Managing Cookies:
Most web browsers allow you to manage your cookie preferences. You can modify your browser settings to accept or reject cookies or delete specific cookies. Please note that if you choose to disable cookies, certain features of our website may not function properly.
## Changes to This Cookie Policy:
We may update this Cookie Policy from time to time to reflect changes in technology or legal requirements. We encourage you to review this page periodically for the latest information on our use of cookies.
## Contact Us:
If you have any questions or concerns about our use of cookies, please contact us at contact@ideolo.co.
`,
}
